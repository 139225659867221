export const FETCH_ACHIEVEMENT_START = 'FETCH_ACHIEVEMENT_START';
export const FETCH_ACHIEVEMENT_SUCCESS = 'FETCH_ACHIEVEMENT_SUCCESS';
export const FETCH_ACHIEVEMENT_FAIL = 'FETCH_ACHIEVEMENT_FAIL';

export const FETCH_ALL_ACHIEVEMENT_START = 'FETCH_ALL_ACHIEVEMENT_START';
export const FETCH_ALL_ACHIEVEMENT_SUCCESS = 'FETCH_ALL_ACHIEVEMENT_SUCCESS';
export const FETCH_ALL_ACHIEVEMENT_FAIL = 'FETCH_ALL_ACHIEVEMENT_FAIL';

export const CREATE_ACHIEVEMENT_START = 'CREATE_ACHIEVEMENT_START';
export const CREATE_ACHIEVEMENT_SUCCESS = 'CREATE_ACHIEVEMENT_SUCCESS';
export const CREATE_ACHIEVEMENT_FAIL = 'CREATE_ACHIEVEMENT_FAIL';

export const UPDATE_ACHIEVEMENT_START = 'UPDATE_ACHIEVEMENT_START';
export const UPDATE_ACHIEVEMENT_SUCCESS = 'UPDATE_ACHIEVEMENT_SUCCESS';
export const UPDATE_ACHIEVEMENT_FAIL = 'UPDATE_ACHIEVEMENT_FAIL';

export const DELETE_ACHIEVEMENT_START = 'DELETE_ACHIEVEMENT_START';
export const DELETE_ACHIEVEMENT_SUCCESS = 'DELETE_ACHIEVEMENT_SUCCESS';
export const DELETE_ACHIEVEMENT_FAIL = 'DELETE_ACHIEVEMENT_FAIL';

export const EXPORT_ACHIEVEMENTS_START = 'EXPORT_ACHIEVEMENTS_START';
export const EXPORT_ACHIEVEMENTS_SUCCESS = 'EXPORT_ACHIEVEMENTS_SUCCESS';
export const EXPORT_ACHIEVEMENTS_FAIL = 'EXPORT_ACHIEVEMENTS_FAIL';

export const EXPORT_ACHIEVEMENTS_PDF_START = 'EXPORT_ACHIEVEMENTS_PDF_START';
export const EXPORT_ACHIEVEMENTS_PDF_SUCCESS =
  'EXPORT_ACHIEVEMENTS_PDF_SUCCESS';
export const EXPORT_ACHIEVEMENTS_PDF_FAIL = 'EXPORT_ACHIEVEMENTS_PDF_FAIL';

export const SEND_ACHIEVEMENTS_TO_PARENT_START =
  'SEND_ACHIEVEMENTS_TO_PARENT_START';
export const SEND_ACHIEVEMENTS_TO_PARENT_SUCCESS =
  'SEND_ACHIEVEMENTS_TO_PARENT_SUCCESS';
export const SEND_ACHIEVEMENTS_TO_PARENT_FAIL =
  'SEND_ACHIEVEMENTS_TO_PARENT_FAIL';

export const RESET_ACHIEVEMENT_STORE = 'RESET_ACHIEVEMENT_STORE';
export const RESET_PARTIALLY_ACHIEVEMENT_STORE =
  'RESET_PARTIALLY_ACHIEVEMENT_STORE';

export const LOGOUT = 'LOGOUT';

export type AchievementActionTypes =
  | typeof FETCH_ACHIEVEMENT_START
  | typeof FETCH_ACHIEVEMENT_SUCCESS
  | typeof FETCH_ACHIEVEMENT_FAIL
  | typeof FETCH_ALL_ACHIEVEMENT_START
  | typeof FETCH_ALL_ACHIEVEMENT_SUCCESS
  | typeof FETCH_ALL_ACHIEVEMENT_FAIL
  | typeof CREATE_ACHIEVEMENT_START
  | typeof CREATE_ACHIEVEMENT_SUCCESS
  | typeof CREATE_ACHIEVEMENT_FAIL
  | typeof UPDATE_ACHIEVEMENT_START
  | typeof UPDATE_ACHIEVEMENT_SUCCESS
  | typeof UPDATE_ACHIEVEMENT_FAIL
  | typeof DELETE_ACHIEVEMENT_START
  | typeof DELETE_ACHIEVEMENT_SUCCESS
  | typeof DELETE_ACHIEVEMENT_FAIL
  | typeof EXPORT_ACHIEVEMENTS_START
  | typeof EXPORT_ACHIEVEMENTS_SUCCESS
  | typeof EXPORT_ACHIEVEMENTS_FAIL
  | typeof EXPORT_ACHIEVEMENTS_PDF_START
  | typeof EXPORT_ACHIEVEMENTS_PDF_SUCCESS
  | typeof EXPORT_ACHIEVEMENTS_PDF_FAIL
  | typeof SEND_ACHIEVEMENTS_TO_PARENT_START
  | typeof SEND_ACHIEVEMENTS_TO_PARENT_SUCCESS
  | typeof SEND_ACHIEVEMENTS_TO_PARENT_FAIL
  | typeof RESET_ACHIEVEMENT_STORE
  | typeof RESET_PARTIALLY_ACHIEVEMENT_STORE
  | typeof LOGOUT;
