import React, { useMemo } from 'react';
import styles from './ParentNavigation.module.scss';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import {
  faBars,
  faSignOutAlt,
  faTimes,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as authService from '../../../../store/auth/service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';
import ParentFreeAttendances from '../ParentFreeAttendances/ParentFreeAttendances';
import { useIntl } from 'react-intl';
import { translate } from '../../../../utility/messageTranslator/translate';
import Button from '../../../Button/Button';
import { User } from '../../../../domain/User';
import Label from '../../../Label/Label';

export type Props = {
  onLogout: () => void;
  onDrawerClick: () => void;
  isMobileMenuOpen: boolean;
  currentUser: User | null;
};

const MOBILE_BREAK_POINT = 900;

export const ParentNavigation = ({
  onLogout,
  onDrawerClick,
  isMobileMenuOpen,
  currentUser,
}: Props) => {
  const { width } = useWindowSize();
  const intl = useIntl();
  const navigate = useNavigate();

  const isProfileInfoReady = useMemo(
    () => !!currentUser?.parent?.branch,
    [currentUser],
  );

  return (
    <>
      <header className={styles.navigationContainer}>
        <div className={styles.navigationItems}>
          <Button onClick={() => navigate(routes.parent.purchase)}>
            {translate(intl, 'NAVIGATION.SIDEBAR_PURCHASE_WORK_TIME')}
          </Button>
          {width && width < MOBILE_BREAK_POINT && isProfileInfoReady && (
            <ParentFreeAttendances />
          )}
          {width && width < MOBILE_BREAK_POINT && !isProfileInfoReady && (
            <Label
              onClick={() => navigate(routes.parent.profile)}
              variant="warning"
            >
              {translate(intl, 'NAVIGATION.PROFILE_INFO_MISSING')}
            </Label>
          )}
        </div>

        <div className={styles.iconItems}>
          {width && width < MOBILE_BREAK_POINT && (
            <IconButton onClick={() => onDrawerClick()}>
              <FontAwesomeIcon
                icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                fixedWidth
                size="sm"
              />
            </IconButton>
          )}
          {width && width > MOBILE_BREAK_POINT && isProfileInfoReady && (
            <ParentFreeAttendances />
          )}
          {width && width > MOBILE_BREAK_POINT && !isProfileInfoReady && (
            <Label
              onClick={() => navigate(routes.parent.profile)}
              variant="warning"
            >
              {translate(intl, 'NAVIGATION.PROFILE_INFO_MISSING')}
            </Label>
          )}
          <IconButton onClick={() => navigate(routes.parent.profile)}>
            <FontAwesomeIcon icon={faCog as IconProp} fixedWidth size="sm" />
          </IconButton>
          <IconButton onClick={() => onLogout()}>
            <FontAwesomeIcon
              icon={faSignOutAlt as IconProp}
              fixedWidth
              size="sm"
            />
          </IconButton>
        </div>
      </header>
    </>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(null, mapDispatchToProps)(ParentNavigation);
