import * as actionTypes from './actionTypes';
import { ChildActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Child } from '../../domain/Child';
import { ListResults } from '../../common/List/List';

export type ChildStateType = {
  childrenList: ListResults<Child> | null;
  childrenLoading: boolean;
  childrenError: HttpError;
  childrenListUpdateNeeded: boolean;
  childCreateLoading: boolean;
  childCreateError: HttpError;
  childCreateSuccess: boolean;
  createdChild: Child | null;
  childUpdateLoading: boolean;
  childUpdateError: HttpError;
  childUpdateSuccess: boolean;
  childDeleteLoading: boolean;
  childDeleteError: HttpError;
  childDeleteSuccess: boolean;
  child: Child | null;
  childLoading: boolean;
  childError: HttpError;
  allChildren: Child[] | null;
  allChildrenLoading: boolean;
  allChildrenError: HttpError;
  registerChildLoading: boolean;
  registerChildError: HttpError;
  registerChildSuccess: boolean;
  agreementBlob: Blob | null;
  latestAgreementLoading: boolean;
  latestAgreementError: HttpError;
  exportChildren: Child[] | null;
  exportChildrenLoading: boolean;
  exportChildrenError: HttpError;
  parentChildren: Child[] | null;
  parentChildrenError: HttpError;
  parentChildrenLoading: boolean;
  updateParentChildLoading: boolean;
  updateParentChildSuccess: boolean;
  updateParentChildError: HttpError;
};

export type ChildActionType = ChildStateType & {
  type: ChildActionTypes;
};

export const initialState: ChildStateType = {
  childrenList: null,
  childrenLoading: true,
  childrenError: null,
  childrenListUpdateNeeded: false,
  childCreateLoading: false,
  childCreateError: null,
  childCreateSuccess: false,
  createdChild: null,
  childUpdateLoading: false,
  childUpdateError: null,
  childUpdateSuccess: false,
  childDeleteLoading: false,
  childDeleteError: null,
  childDeleteSuccess: false,
  child: null,
  childLoading: false,
  childError: null,
  allChildren: null,
  allChildrenError: null,
  allChildrenLoading: false,
  registerChildError: null,
  registerChildLoading: false,
  registerChildSuccess: false,
  agreementBlob: null,
  latestAgreementError: null,
  latestAgreementLoading: false,
  exportChildren: null,
  exportChildrenError: null,
  exportChildrenLoading: false,
  parentChildren: null,
  parentChildrenError: null,
  parentChildrenLoading: false,
  updateParentChildSuccess: false,
  updateParentChildError: null,
  updateParentChildLoading: false,
};

const fetchChildrenStart = (state: ChildStateType): ChildStateType => ({
  ...state,
  childrenLoading: true,
});

const fetchChildrenSuccess = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  childrenList: action.childrenList,
  childrenLoading: false,
  childrenError: null,
  childrenListUpdateNeeded: false,
});

const fetchChildrenFail = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  childrenError: action.childrenError,
  childrenLoading: false,
});

const fetchAllChildrenStart = (state: ChildStateType): ChildStateType => ({
  ...state,
  allChildrenLoading: true,
});

const fetchAllChildrenSuccess = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  allChildren: action.allChildren,
  allChildrenLoading: false,
  allChildrenError: null,
});

const fetchAllChildrenFail = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  allChildrenError: action.allChildrenError,
  allChildrenLoading: false,
});

const fetchParentChildrenStart = (state: ChildStateType): ChildStateType => ({
  ...state,
  parentChildrenLoading: true,
  childrenListUpdateNeeded: false,
  updateParentChildSuccess: false,
});

const fetchParentChildrenSuccess = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  parentChildren: action.parentChildren,
  parentChildrenLoading: false,
  parentChildrenError: null,
});

const fetchParentChildrenFail = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  parentChildrenError: action.parentChildrenError,
  parentChildrenLoading: false,
});

const fetchChildStart = (state: ChildStateType): ChildStateType => ({
  ...state,
  childLoading: true,
  childCreateSuccess: false,
  childCreateError: null,
  updateParentChildSuccess: false,
});

const fetchChildSuccess = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  child: action.child,
  childLoading: false,
  childError: null,
});

const fetchChildFail = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  childError: action.childError,
  childLoading: false,
});

const createChildStart = (state: ChildStateType): ChildStateType => ({
  ...state,
  childCreateLoading: true,
});

const createChildSuccess = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  childCreateLoading: false,
  childCreateError: null,
  childCreateSuccess: true,
  createdChild: action.createdChild,
});

const createChildFail = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  childCreateLoading: false,
  childCreateError: action.childCreateError,
});

const registerChildStart = (state: ChildStateType): ChildStateType => ({
  ...state,
  registerChildError: null,
  registerChildLoading: true,
});

const registerChildSuccess = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  registerChildLoading: false,
  registerChildError: null,
  registerChildSuccess: true,
});

const registerChildFail = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  registerChildLoading: false,
  registerChildError: action.registerChildError,
});

const updateParentChildStart = (state: ChildStateType): ChildStateType => ({
  ...state,
  updateParentChildError: null,
  updateParentChildLoading: true,
});

const updateParentChildSuccess = (state: ChildStateType): ChildStateType => ({
  ...state,
  updateParentChildLoading: false,
  updateParentChildError: null,
  updateParentChildSuccess: true,
});

const updateParentChildFail = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  updateParentChildLoading: false,
  updateParentChildError: action.updateParentChildError,
});

const updateChildStart = (state: ChildStateType): ChildStateType => ({
  ...state,
  childUpdateLoading: true,
  childUpdateSuccess: false,
});

const updateChildSuccess = (state: ChildStateType): ChildStateType => ({
  ...state,
  childUpdateLoading: false,
  childUpdateError: null,
  childUpdateSuccess: true,
});

const updateChildFail = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  childUpdateLoading: false,
  childUpdateError: action.childUpdateError,
});

const deleteChildStart = (state: ChildStateType): ChildStateType => ({
  ...state,
  childDeleteLoading: true,
});

const deleteChildSuccess = (state: ChildStateType): ChildStateType => ({
  ...state,
  childDeleteLoading: false,
  childDeleteError: null,
  childDeleteSuccess: true,
  childrenListUpdateNeeded: true,
});

const deleteChildFail = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  childDeleteLoading: false,
  childDeleteError: action.childDeleteError,
});

const getLatestAgreementStart = (state: ChildStateType): ChildStateType => ({
  ...state,
  latestAgreementLoading: true,
});

const getLatestAgreementSuccess = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  latestAgreementLoading: false,
  latestAgreementError: null,
  agreementBlob: action.agreementBlob,
});

const getLatestAgreementFail = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  latestAgreementLoading: false,
  latestAgreementError: action.latestAgreementError,
});

const exportChildrenStart = (state: ChildStateType): ChildStateType => ({
  ...state,
  exportChildrenLoading: true,
});

const exportChildrenSuccess = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  exportChildrenLoading: false,
  exportChildrenError: null,
  exportChildren: action.exportChildren,
});

const exportChildrenFail = (
  state: ChildStateType,
  action: ChildActionType,
): ChildStateType => ({
  ...state,
  exportChildrenLoading: false,
  exportChildrenError: action.exportChildrenError,
});

const resetExportChildren = (state: ChildStateType): ChildStateType => ({
  ...state,
  exportChildren: null,
  exportChildrenError: null,
  exportChildrenLoading: false,
});

const refreshChildList = (state: ChildStateType): ChildStateType => ({
  ...state,
  childrenListUpdateNeeded: true,
});

const resetChildPreview = (state: ChildStateType): ChildStateType => ({
  ...state,
  agreementBlob: null,
});

const resetChildStore = (state: ChildStateType): ChildStateType => ({
  ...initialState,
});

const completelyResetChildStore = (): ChildStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ChildActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CHILDREN_START:
      return fetchChildrenStart(state);
    case actionTypes.FETCH_CHILDREN_SUCCESS:
      return fetchChildrenSuccess(state, action);
    case actionTypes.FETCH_CHILDREN_FAIL:
      return fetchChildrenFail(state, action);
    case actionTypes.FETCH_ALL_CHILDREN_START:
      return fetchAllChildrenStart(state);
    case actionTypes.FETCH_ALL_CHILDREN_SUCCESS:
      return fetchAllChildrenSuccess(state, action);
    case actionTypes.FETCH_ALL_CHILDREN_FAIL:
      return fetchAllChildrenFail(state, action);
    case actionTypes.FETCH_PARENT_CHILDREN_START:
      return fetchParentChildrenStart(state);
    case actionTypes.FETCH_PARENT_CHILDREN_SUCCESS:
      return fetchParentChildrenSuccess(state, action);
    case actionTypes.FETCH_PARENT_CHILDREN_FAIL:
      return fetchParentChildrenFail(state, action);
    case actionTypes.FETCH_CHILD_START:
      return fetchChildStart(state);
    case actionTypes.FETCH_CHILD_SUCCESS:
      return fetchChildSuccess(state, action);
    case actionTypes.FETCH_CHILD_FAIL:
      return fetchChildFail(state, action);
    case actionTypes.CREATE_CHILD_START:
      return createChildStart(state);
    case actionTypes.CREATE_CHILD_SUCCESS:
      return createChildSuccess(state, action);
    case actionTypes.CREATE_CHILD_FAIL:
      return createChildFail(state, action);
    case actionTypes.REGISTER_CHILD_START:
      return registerChildStart(state);
    case actionTypes.REGISTER_CHILD_SUCCESS:
      return registerChildSuccess(state, action);
    case actionTypes.REGISTER_CHILD_FAIL:
      return registerChildFail(state, action);
    case actionTypes.UPDATE_CHILD_START:
      return updateChildStart(state);
    case actionTypes.UPDATE_CHILD_SUCCESS:
      return updateChildSuccess(state);
    case actionTypes.UPDATE_CHILD_FAIL:
      return updateChildFail(state, action);
    case actionTypes.DELETE_CHILD_START:
      return deleteChildStart(state);
    case actionTypes.DELETE_CHILD_SUCCESS:
      return deleteChildSuccess(state);
    case actionTypes.DELETE_CHILD_FAIL:
      return deleteChildFail(state, action);
    case actionTypes.GET_LATEST_AGREEMENT_START:
      return getLatestAgreementStart(state);
    case actionTypes.GET_LATEST_AGREEMENT_SUCCESS:
      return getLatestAgreementSuccess(state, action);
    case actionTypes.GET_LATEST_AGREEMENT_FAIL:
      return getLatestAgreementFail(state, action);
    case actionTypes.EXPORT_CHILDREN_START:
      return exportChildrenStart(state);
    case actionTypes.EXPORT_CHILDREN_SUCCESS:
      return exportChildrenSuccess(state, action);
    case actionTypes.EXPORT_CHILDREN_FAIL:
      return exportChildrenFail(state, action);
    case actionTypes.RESET_EXPORT_CHILDREN:
      return resetExportChildren(state);
    case actionTypes.REFRESH_CHILD_LIST:
      return refreshChildList(state);
    case actionTypes.UPDATE_PARENT_CHILD_START:
      return updateParentChildStart(state);
    case actionTypes.UPDATE_PARENT_CHILD_SUCCESS:
      return updateParentChildSuccess(state);
    case actionTypes.UPDATE_PARENT_CHILD_FAIL:
      return updateParentChildFail(state, action);
    case actionTypes.REFRESH_CHILD_PREVIEW:
      return resetChildPreview(state);
    case actionTypes.RESET_CHILD_STORE:
      return resetChildStore(state);
    case actionTypes.LOGOUT:
      return completelyResetChildStore();
    default:
      return state;
  }
};

export default reducer;
