export const FETCH_CHILDREN_START = 'FETCH_CHILDREN_START';
export const FETCH_CHILDREN_SUCCESS = 'FETCH_CHILDREN_SUCCESS';
export const FETCH_CHILDREN_FAIL = 'FETCH_CHILDREN_FAIL';

export const FETCH_ALL_CHILDREN_START = 'FETCH_ALL_CHILDREN_START';
export const FETCH_ALL_CHILDREN_SUCCESS = 'FETCH_ALL_CHILDREN_SUCCESS';
export const FETCH_ALL_CHILDREN_FAIL = 'FETCH_ALL_CHILDREN_FAIL';

export const FETCH_PARENT_CHILDREN_START = 'FETCH_PARENT_CHILDREN_START';
export const FETCH_PARENT_CHILDREN_SUCCESS = 'FETCH_PARENT_CHILDREN_SUCCESS';
export const FETCH_PARENT_CHILDREN_FAIL = 'FETCH_PARENT_CHILDREN_FAIL';

export const FETCH_CHILD_START = 'FETCH_CHILD_START';
export const FETCH_CHILD_SUCCESS = 'FETCH_CHILD_SUCCESS';
export const FETCH_CHILD_FAIL = 'FETCH_CHILD_FAIL';

export const CREATE_CHILD_START = 'CREATE_CHILD_START';
export const CREATE_CHILD_SUCCESS = 'CREATE_CHILD_SUCCESS';
export const CREATE_CHILD_FAIL = 'CREATE_CHILD_FAIL';

export const REGISTER_CHILD_START = 'REGISTER_CHILD_START';
export const REGISTER_CHILD_SUCCESS = 'REGISTER_CHILD_SUCCESS';
export const REGISTER_CHILD_FAIL = 'REGISTER_CHILD_FAIL';

export const UPDATE_PARENT_CHILD_START = 'UPDATE_PARENT_CHILD_START';
export const UPDATE_PARENT_CHILD_SUCCESS = 'UPDATE_PARENT_CHILD_SUCCESS';
export const UPDATE_PARENT_CHILD_FAIL = 'UPDATE_PARENT_CHILD_FAIL';

export const UPDATE_CHILD_START = 'UPDATE_CHILD_START';
export const UPDATE_CHILD_SUCCESS = 'UPDATE_CHILD_SUCCESS';
export const UPDATE_CHILD_FAIL = 'UPDATE_CHILD_FAIL';

export const DELETE_CHILD_START = 'DELETE_CHILD_START';
export const DELETE_CHILD_SUCCESS = 'DELETE_CHILD_SUCCESS';
export const DELETE_CHILD_FAIL = 'DELETE_CHILD_FAIL';

export const GET_LATEST_AGREEMENT_START = 'GET_LATEST_AGREEMENT_START';
export const GET_LATEST_AGREEMENT_SUCCESS = 'GET_LATEST_AGREEMENT_SUCCESS';
export const GET_LATEST_AGREEMENT_FAIL = 'GET_LATEST_AGREEMENT_FAIL';

export const EXPORT_CHILDREN_START = 'EXPORT_CHILDREN_START';
export const EXPORT_CHILDREN_SUCCESS = 'EXPORT_CHILDREN_SUCCESS';
export const EXPORT_CHILDREN_FAIL = 'EXPORT_CHILDREN_FAIL';
export const RESET_EXPORT_CHILDREN = 'RESET_EXPORT_CHILDREN';

export const REFRESH_CHILD_LIST = 'REFRESH_CHILD_LIST';
export const REFRESH_CHILD_PREVIEW = 'REFRESH_CHILD_PREVIEW';

export const RESET_CHILD_STORE = 'RESET_CHILD_STORE';

export const LOGOUT = 'LOGOUT';

export type ChildActionTypes =
  | typeof FETCH_CHILDREN_START
  | typeof FETCH_CHILDREN_SUCCESS
  | typeof FETCH_CHILDREN_FAIL
  | typeof FETCH_ALL_CHILDREN_START
  | typeof FETCH_ALL_CHILDREN_SUCCESS
  | typeof FETCH_ALL_CHILDREN_FAIL
  | typeof FETCH_PARENT_CHILDREN_START
  | typeof FETCH_PARENT_CHILDREN_SUCCESS
  | typeof FETCH_PARENT_CHILDREN_FAIL
  | typeof FETCH_CHILD_START
  | typeof FETCH_CHILD_SUCCESS
  | typeof FETCH_CHILD_FAIL
  | typeof CREATE_CHILD_START
  | typeof CREATE_CHILD_SUCCESS
  | typeof CREATE_CHILD_FAIL
  | typeof REGISTER_CHILD_START
  | typeof REGISTER_CHILD_SUCCESS
  | typeof REGISTER_CHILD_FAIL
  | typeof UPDATE_PARENT_CHILD_START
  | typeof UPDATE_PARENT_CHILD_SUCCESS
  | typeof UPDATE_PARENT_CHILD_FAIL
  | typeof UPDATE_CHILD_START
  | typeof UPDATE_CHILD_SUCCESS
  | typeof UPDATE_CHILD_FAIL
  | typeof DELETE_CHILD_START
  | typeof DELETE_CHILD_SUCCESS
  | typeof DELETE_CHILD_FAIL
  | typeof GET_LATEST_AGREEMENT_START
  | typeof GET_LATEST_AGREEMENT_SUCCESS
  | typeof GET_LATEST_AGREEMENT_FAIL
  | typeof EXPORT_CHILDREN_START
  | typeof EXPORT_CHILDREN_SUCCESS
  | typeof EXPORT_CHILDREN_FAIL
  | typeof RESET_EXPORT_CHILDREN
  | typeof RESET_CHILD_STORE
  | typeof REFRESH_CHILD_PREVIEW
  | typeof REFRESH_CHILD_LIST
  | typeof LOGOUT;
