import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { WorkTime } from '../../domain/WorkTime';
import { ListResults } from '../../common/List/List';
import { Attendance } from '../../domain/Attendance';

export const fetchWorkTimesStart = () => ({
  type: actionTypes.FETCH_WORK_TIMES_START,
});

export const fetchWorkTimesSuccess = (
  workTimesList: ListResults<WorkTime>,
) => ({
  type: actionTypes.FETCH_WORK_TIMES_SUCCESS,
  workTimesList,
});

export const fetchWorkTimesFail = (workTimesError: HttpError) => ({
  type: actionTypes.FETCH_WORK_TIMES_FAIL,
  workTimesError,
});

export const fetchWeeklyWorkTimesStart = () => ({
  type: actionTypes.FETCH_WEEKLY_WORK_TIMES_START,
});

export const fetchWeeklyWorkTimesSuccess = (weeklyWorkTimes: WorkTime[]) => ({
  type: actionTypes.FETCH_WEEKLY_WORK_TIMES_SUCCESS,
  weeklyWorkTimes,
});

export const fetchWeeklyWorkTimesFail = (weeklyWorkTimesError: HttpError) => ({
  type: actionTypes.FETCH_WEEKLY_WORK_TIMES_FAIL,
  weeklyWorkTimesError,
});

export const fetchWorkTimeStart = () => ({
  type: actionTypes.FETCH_WORK_TIME_START,
});

export const fetchWorkTimeSuccess = (workTime: WorkTime) => ({
  type: actionTypes.FETCH_WORK_TIME_SUCCESS,
  workTime,
});

export const fetchWorkTimeFail = (workTimeError: HttpError) => ({
  type: actionTypes.FETCH_WORK_TIME_FAIL,
  workTimeError,
});

export const fetchAllWorkTimesStart = () => ({
  type: actionTypes.FETCH_ALL_WORK_TIMES_START,
});

export const fetchAllWorkTimesSuccess = (allWorkTimes: WorkTime[]) => ({
  type: actionTypes.FETCH_ALL_WORK_TIMES_SUCCESS,
  allWorkTimes,
});

export const fetchAllWorkTimesFail = (allWorkTimesError: HttpError) => ({
  type: actionTypes.FETCH_ALL_WORK_TIMES_FAIL,
  allWorkTimesError,
});

export const createWorkTimeStart = () => ({
  type: actionTypes.CREATE_WORK_TIME_START,
});

export const createWorkTimeSuccess = (createdWorkTime: WorkTime) => ({
  type: actionTypes.CREATE_WORK_TIME_SUCCESS,
  createdWorkTime,
});

export const createWorkTimeFail = (workTimeCreateError: HttpError) => ({
  type: actionTypes.CREATE_WORK_TIME_FAIL,
  workTimeCreateError,
});

export const updateWorkTimeStart = () => ({
  type: actionTypes.UPDATE_WORK_TIME_START,
});

export const updateWorkTimeSuccess = () => ({
  type: actionTypes.UPDATE_WORK_TIME_SUCCESS,
});

export const updateWorkTimeFail = (workTimeUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_WORK_TIME_FAIL,
  workTimeUpdateError,
});

export const toggleWorkTimeStatusStart = () => ({
  type: actionTypes.TOGGLE_WORK_TIME_STATUS_START,
});

export const toggleWorkTimeStatusSuccess = () => ({
  type: actionTypes.TOGGLE_WORK_TIME_STATUS_SUCCESS,
});

export const toggleWorkTimeStatusFail = (
  toggleWorkTimeStatusError: HttpError,
) => ({
  type: actionTypes.TOGGLE_WORK_TIME_STATUS_FAIL,
  toggleWorkTimeStatusError,
});

export const deleteWorkTimeStart = () => ({
  type: actionTypes.DELETE_WORK_TIME_START,
});

export const deleteWorkTimeSuccess = () => ({
  type: actionTypes.DELETE_WORK_TIME_SUCCESS,
});

export const deleteWorkTimeFail = (workTimeDeleteError: HttpError) => ({
  type: actionTypes.DELETE_WORK_TIME_FAIL,
  workTimeDeleteError,
});

export const fetchWorkTimeAttendancesStart = () => ({
  type: actionTypes.FETCH_WORK_TIME_ATTENDANCES_START,
});

export const fetchWorkTimeAttendancesSuccess = (
  allWorkTimeAttendances: WorkTime[],
) => ({
  type: actionTypes.FETCH_WORK_TIME_ATTENDANCES_SUCCESS,
  allWorkTimeAttendances,
});

export const fetchWorkTimeAttendancesFail = (
  allWorkTimeAttendancesError: HttpError,
) => ({
  type: actionTypes.FETCH_WORK_TIME_ATTENDANCES_FAIL,
  allWorkTimeAttendancesError,
});

export const setAttendanceToggleValue = (
  attendanceToggleValue: Attendance,
) => ({
  type: actionTypes.SET_ATTENDANCE_TOGGLE_VALUE,
  attendanceToggleValue,
});

export const setAttendanceDeleteValue = (attendanceId: number) => ({
  type: actionTypes.SET_ATTENDANCE_DELETE_VALUE,
  attendanceId,
});

export const resetPartiallyWorkTimeStore = () => ({
  type: actionTypes.RESET_PARTIALLY_WORK_TIME_STORE,
});

export const resetWorkTimeStore = () => ({
  type: actionTypes.RESET_WORK_TIME_STORE,
});

export const refreshWorkTimeStore = () => ({
  type: actionTypes.REFRESH_WORK_TIME_STORE,
});
